import { useCallback, useEffect, useSyncExternalStore } from "react";
import { ExternalStoreListeners } from "./ExternalStoreListeners";
export class SSRGlobalCache {
    async invalidate() {
        if (this.refetch) {
            let result = await this.refetch();
            return this.current = result, this.listeners.notify(), result;
        }
        throw Error("No refetch function");
    }
    update(value) {
        this.current = value, this.listeners.notify();
    }
    constructor(){
        this.listeners = new ExternalStoreListeners();
    }
}
export function useSSRFetch(cache, ssr, refetch, dependencies) {
    return useEffect(()=>{
        cache.current = void 0, cache.refetch = refetch;
    }, dependencies), useSyncExternalStore(cache.listeners.on, useCallback(()=>{
        var _current;
        return null != (_current = cache.current) ? _current : cache.current = ssr;
    }, dependencies), useCallback(()=>ssr, dependencies));
}
