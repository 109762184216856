function _class_private_field_loose_base(receiver, privateKey) {
    if (!Object.prototype.hasOwnProperty.call(receiver, privateKey)) throw TypeError("attempted to use private field on non-instance");
    return receiver;
}
var id = 0, _listeners = "__private_" + id++ + "__listeners";
export class ExternalStoreListeners {
    constructor(){
        Object.defineProperty(this, _listeners, {
            writable: !0,
            value: void 0
        }), _class_private_field_loose_base(this, _listeners)[_listeners] = new Set(), this.on = (fn)=>(_class_private_field_loose_base(this, _listeners)[_listeners].add(fn), ()=>_class_private_field_loose_base(this, _listeners)[_listeners].delete(fn)), this.notify = ()=>{
            _class_private_field_loose_base(this, _listeners)[_listeners].forEach((fn)=>fn());
        };
    }
}
